import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollToPlugin);

export default class News {
    constructor() {
        this.init();
    }

    init() {
        this.getNews();
    }

    eventBind() {
        const newsOpen = [...document.querySelectorAll(".js-news_open")];
        const newsClose = [...document.querySelectorAll(".js-news_close")];
        const news = document.querySelector(".js-news");

        newsOpen.forEach((el, index) => {
            el.addEventListener("click", () => {
                document.body.classList.add("-lock");
                news.setAttribute("data-active", "1");

                if (index !== 0) {
                    const id = el.getAttribute("data-id");
                    this.scrollToTargetID(id);
                } else {
                    //スクロールリセット
                    const scrollwrapper = document.querySelector(".news__content");
                    scrollwrapper.scrollTo(0, 0);
                }
            });
        });

        newsClose.forEach((el) => {
            el.addEventListener("click", () => {
                document.body.classList.remove("-lock");
                news.setAttribute("data-active", "0");
            });
        });
    }

    scrollToTargetID(id) {
        gsap.to(".news__content", { duration: 1, scrollTo: "#news_" + id, ease: "circ.inOut" });
    }

    newsTickerStart() {
        const items = [...document.querySelectorAll(".js-newsticker li")];
        let index = 0;
        const delay = 5000;

        const loop = () => {
            items.forEach((item) => item.setAttribute("data-active", "0"));
            items[index].setAttribute("data-active", "1");
            index++;

            if (index > items.length - 1) {
                index = 0;
            }

            setTimeout(loop, delay);
        };

        loop();
    }

    setNewsDOM(newsList) {
        //newsbarへの追加
        const tickerInner = document.querySelector(".js-newsticker");
        let listDOM = "";

        newsList.forEach((news) => {
            listDOM += `
            <li class="l-newsticker__list__item js-news_open" data-id="${news.id}">
            <div class="ovw"><time>${news.publishedDate}</time>${news.title}</div>
            <button class="l-newsticker__more">VIEW MORE<i class="arw"></i></button>
            </li>
       `;
        });

        tickerInner.innerHTML = listDOM;
        this.newsTickerStart();

        //newsmodalへの追加
        const newsBody = document.querySelector(".js-newsdombody");
        let newsDOM = "";
        newsList.forEach((news) => {
            newsDOM += `
            <article class="news__article" id="news_${news.id}">
                <time class="news__article__date">${news.publishedDate}</time>
                <h2 class="news__article__ttl">${news.title}</h2>
                <div class="news__article__body">
                    ${news.body}
                </div>
            </article>
            `;
        });
        newsBody.innerHTML = newsDOM;
    }

    async getNews() {
        const jsonurl = location.href.match(/localhost|192.168./) ? "/assets/_inc/page/top/data/news_test.json" : "/api/news_json.html";
        const res = await fetch(jsonurl);
        const resjson = await res.json();
        const newsList = resjson.resultset;
        this.setNewsDOM(newsList);
        this.eventBind();
    }
}
