import { gsap } from "gsap";

export default class Panel {
    constructor() {
        this.active = false;
        this.init();
    }

    init() {
        this.eventBind();
    }

    updateDomState() {
        const panelRef = document.querySelector(".panel");
        panelRef.setAttribute("data-active", this.active ? "1" : "0");
    }

    eventBind() {
        const innerRef = document.querySelector(".panel__inner");
        const opener = [...document.querySelectorAll(".js-panelopen")];

        opener.forEach((btn) => {
            btn.addEventListener("click", () => {
                this.active = !this.active;
                this.updateDomState();
                innerRef.scrollTo(0, 0);
                document.body.classList.toggle("-lock");
            });
        });
    }
}
