import { gsap } from "gsap";

export default class KV {
    constructor() {
        this.init();
    }

    init() {
        this.introIn()

        setTimeout(this.inAnimation.bind(this), 4000);
        setTimeout(() => {
            document.body.setAttribute("data-introend", "1");
            this.introEnd = true;
        }, 4000);
    }

    shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            let j = Math.floor(Math.random() * (i + 1)); // 0 から i のランダムなインデックス
            [array[i], array[j]] = [array[j], array[i]]; // 要素を入れ替えます
        }
    }

    inAnimation() {
        const elements = [...document.querySelectorAll(".kv__element__item")];
        this.shuffleArray(elements);

        elements.forEach((elm, index) => {
            const randDelay = Math.random() * 0.8;
            gsap.fromTo(elm, { opacity: 0 }, { opacity: 1, duration: 0.5, delay: 0.07 * index + 0.5 });

            setTimeout(() => {
                elm.classList.add("-anim");
            }, 1000 * randDelay);
        });

        gsap.fromTo(".kv__window", { opacity: 0, scale: 1.1 }, { opacity: 1, scale: 1, ease: "circ.inOut", duration: 0.8, delay: 1.5 });
    }

    introIn() {
        window.addEventListener('load', () => {
            document.body.classList.add('-load');
        })
    }
}
