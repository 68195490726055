import Swiper from "swiper/bundle";
import "swiper/css/bundle";

export default class Goods {
    constructor() {
        this.goodsList = null;
        this.init();
    }

    init() {
        this.getGoods();
    }

    eventBind() {
        const goodsOpen = [...document.querySelectorAll(".js-goods_open")];
        const goodsClose = [...document.querySelectorAll(".js-goods_close")];
        const goods = document.querySelector(".js-goods");

        goodsOpen.forEach((el) => {
            el.addEventListener("click", () => {
                const id = parseInt(el.getAttribute("data-id"));
                document.body.classList.add("-lock");
                goods.setAttribute("data-active", "1");

                this.setGoodsDetail(id);
            });
        });

        goodsClose.forEach((el) => {
            el.addEventListener("click", () => {
                document.body.classList.remove("-lock");
                goods.setAttribute("data-active", "0");
                this.clearModalDom();
            });
        });
    }

    clearModalDom() {
        const infowrapper = document.querySelector(".js-goodsinfo");
        const phwrapper = document.querySelector(".js-goodsph");

        infowrapper.innerHTML = "";
        phwrapper.innerHTML = "";
    }

    setGoodsDetail(id) {
        let mygoods = this.goodsList.filter((item) => item.id === id);
        if (!mygoods) return;

        mygoods = mygoods[0];

        //情報部分
        const infowrapper = document.querySelector(".js-goodsinfo");
        let infoDOM = `
        <h3 class="goods_modal__info__ttl">${mygoods.name}</h3>
        <h4 class="goods_modal__info__price">¥${mygoods.price}<span class="taxin">（税込）</span></h4>
        <ul class="goods_modal__info__category">
            <li>${mygoods.categories.name}</li>
        </ul>
        <h5 class="goods_modal__info__subttl">■商品説明</h5>
        <div class="goods_modal__info__body">
            ${mygoods.description}
        </div>
        `;

        infowrapper.innerHTML = infoDOM;

        //slider部分
        const phwrapper = document.querySelector(".js-goodsph");
        let phdom = "";
        let isSwiper = false;
        if (!mygoods.images[0]) {
            phdom += '<div class="goods_modal__ph__np"></div>';
        } else if (mygoods.images[0] && mygoods.images.length === 1) {
            phdom += `<img src="${mygoods.images[0]}">`;
        } else {
            isSwiper = true;
            phdom += `<div class="swiper goodsmodal_swiper"><div class="swiper-wrapper">`;

            //slideループ
            mygoods.images.forEach((ph) => {
                phdom += `<div class="swiper-slide"><img src="${ph}"></div>`;
            });

            phdom += `</div><div class="swiper-pagination"></div></div>`;
        }
        phwrapper.innerHTML = phdom;

        //swiperが存在すればinit
        if (isSwiper) {
            const swiper = new Swiper(".goodsmodal_swiper", {
                loop: true,
                speed: 1000,
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                },
                autoplay: {
                    delay: 2000,
                },
            });
        }
    }

    setGoodsDOM(goodsList) {
        const listwrapper = document.querySelector(".js-goodslist");
        let listDOM = "";

        goodsList.forEach((goods) => {
            listDOM += `
                <li class="goodslist__item">
                    <p class="thumb js-goods_open" data-id="${goods.id}">
                        <img src="${goods.images[0] ? goods.images[0] : "/assets/img/page/top/goods/ph_np.jpg"}" alt="商品画像">
                    </p>
                    <h4 class="ttl">${goods.name}</h4>
                </li>
                `;
        });

        listwrapper.innerHTML = listDOM;
    }

    async getGoods() {
        const jsonurl = location.href.match(/localhost|192.168./) ? "/assets/_inc/page/top/data/goods_test.json" : "/api/goods_json.html";
        const res = await fetch(jsonurl);
        const resjson = await res.json();
        const goodsList = resjson.items;
        this.goodsList = goodsList;
        this.setGoodsDOM(goodsList);
        this.eventBind();
    }
}
