import Panel from "./classes/panel.es6";
import News from "./classes/news.es6";
import Goods from "./classes/goods.es6";
import TicketBnr from "./classes/ticketbnr.es6";
import KV from "./classes/kv.es6";

export default class Top {
    constructor() {
        this.panel = new Panel();
        this.news = new News();
        this.goods = new Goods();
        this.ticketbnr = new TicketBnr();
        this.kv = new KV();
    }
}
