export default class TicketBnr {
    constructor() {
        this.active = false;
        this.dom = document.querySelector(".js-ticketbnr");
        this.init();
    }

    init() {
        this.eventBind();
    }

    updateDOM() {
        this.dom.setAttribute("data-active", this.active ? "1" : "0");
    }

    eventBind() {
        const trigger = document.querySelector(".js-ticketbnr_trigger");
        trigger.addEventListener("click", () => {
            this.active = !this.active;
            this.updateDOM();
        });
    }
}
